import { QueryClient, dehydrate } from "@tanstack/react-query";
import AdUnitWithPosition from "src/components/forsiden/AdUnitWithPosition";
import ArticleBlockV2 from "src/components/forsiden/ArticleBlockV2";
import { movieListPosterQuery } from "src/components/forsiden/StreamingPosterRowWithSelfLoad";
import InfoBox from "src/components/infobox/InfoBox";
import FilmwebSGLogoSvg from "src/components/svg/FilmwebSGLogoSvg";
import theme from "src/components/themes/DefaultTheme";
import { envConfig } from "src/lib/client/envConfig";
import { buildFilminfoQueryKey, filminfoRequest } from "src/lib/client/useFilminfo";
import AdWrapper from "src/lib/contexts/AdContext";
import { setDefaultCacheHeaders } from "src/lib/server/cache";
import { ICommonProps, IQueryResult, getServerData, isPreview, sanityQuery } from "src/lib/server/sanity";
import styled from "src/lib/styles/css";
import IForsidenV2, { GroupedLayoutObject, GroupedLayoutObjectWithSubgroups, IStreamingSearchBox, ItemType_ArticleRowBlock, ItemType_ArticleRowBlock24, ItemType_ArticlesAndList, ItemType_BlockWithAd, ItemType_ListPosterRow, ItemType_MostReadNews, ItemType_PosterList, ItemType_SquareArticleRowBlock, ItemType_StreamingSearchBox, ItemType_TopBlock, ItemType_VerticalArticleList, ItemType_VerticalArticleListGroup, PublishableDocument } from "src/lib/types/IForsidenV2";
import IMovieList from "src/lib/types/MovieList";
import { groq } from "next-sanity";
import Head from 'next/head';
import BgImagePageWrapper from "src/components/takoversponsor/BgImagePageWrapper";
import StickyBottomAd from "src/components/ads/StickyBottomAd";


//#region [Other]
const ARTICLE_REF: string = groq`
    _id,
    _type,
    slug,
    mainVersionEDI,
    title,
    sectionPageTitle,
    isStreaming,
    isKinoklubb,
    extLink,
    "mainPosterV2": select(
        (mainPosterV2 != null) => {...mainPosterV2, "asset": mainPosterV2.asset->},
        (postersV2 != null) => {...postersV2[0], "asset": postersV2[0].asset->},
        null
    ),
    isAdContent,
    "imageV2": select(
        (sectionPageImageV2 != null) => {...sectionPageImageV2, "asset": sectionPageImageV2.asset->},
        (mainImageV2 != null) => {...mainImageV2, "asset": mainImageV2.asset->},
        (imageV2 != null) => {...imageV2[0], "asset": imageV2[0].asset->},
        (imagesV2 != null) => {...imagesV2[0], "asset": imagesV2[0].asset->},
        null
    ),
    related[]-> {
        _id,
        _type,
    },
    streamingIds
`;

const PAGE_QUERY: string = groq`
{
	"published": *[_id == $pageId] {
		...,
        "articleList": articleList[] {
            ...,
            _type == "verticalArticleList" => {
                ...,
                articleList[]->{${ARTICLE_REF}}
            },
            _type == "posterList" => {
                ...,
                movies[]->{${ARTICLE_REF}}
            },
            _type == "reference" => @-> {
                ${ARTICLE_REF}
            }
        },
        "mostReadNewsArticleList": mostReadNewsArticleList[]-> {
            ${ARTICLE_REF}
        }
	}[0]
}
`;
//#endregion

type CustomPageProps = {
    sgTopLogo?: boolean;
    hasCustomBg?: boolean;
    customBgTargetAdId?: string;
    hasStickyBottomAd?: boolean;
    pageTitle?: string;
    adSetup: "frontpage" | "streamingguide" | "news";
}

//#region [Other]getServerSideProps
export function getSSRData(pageId: string, customPageProps: CustomPageProps | null) {
    return getServerData(async (context) => {
        setDefaultCacheHeaders(context);
        const isP = isPreview(context);
        const queryResult = await sanityQuery<IQueryResult<IForsidenV2>>(PAGE_QUERY, { pageId: pageId }, { isPreview: isP });
        const page = queryResult?.published;

        // Get data for any movie lists
        let queryClient: QueryClient | undefined;

        // group the articles into something easier to work with
        const layouts: (GroupedLayoutObject | IStreamingSearchBox | GroupedLayoutObjectWithSubgroups)[] = [];
        if ((page?.articleList?.length ?? 0) > 0) {
            let currentLayout: GroupedLayoutObject | GroupedLayoutObjectWithSubgroups | undefined;
            for (const art of page!.articleList!) {
                switch (art._type) {
                    case ItemType_PosterList:
                    case ItemType_TopBlock:
                    case ItemType_BlockWithAd:
                    case ItemType_ArticleRowBlock:
                    case ItemType_ArticleRowBlock24:
                    case ItemType_SquareArticleRowBlock:
                    case ItemType_ListPosterRow:
                    case ItemType_VerticalArticleListGroup:
                    case ItemType_ArticlesAndList:
                        currentLayout = art;
                        layouts.push(currentLayout);
                        break;
                    case ItemType_VerticalArticleList:
                        art.articles = (art as any).articleList;
                        if (((currentLayout as GroupedLayoutObjectWithSubgroups)?.layouts?.length ?? 0) === 0) {
                            (currentLayout as GroupedLayoutObjectWithSubgroups).layouts = [];
                        }
                        (currentLayout as GroupedLayoutObjectWithSubgroups).layouts?.push(art);
                        break;
                    case ItemType_MostReadNews:
                        art.articles = page?.mostReadNewsArticleList ?? [];
                        if (((currentLayout as GroupedLayoutObjectWithSubgroups)?.layouts?.length ?? 0) === 0) {
                            (currentLayout as GroupedLayoutObjectWithSubgroups).layouts = [];
                        }
                        (currentLayout as GroupedLayoutObjectWithSubgroups).layouts?.push(art);
                        break;
                    case ItemType_StreamingSearchBox:
                        currentLayout = undefined;
                        layouts.push(art);
                        break;
                    default:
                        if (currentLayout) {
                            if (!currentLayout!.articles) {
                                currentLayout!.articles = [];
                            }
                            (currentLayout as GroupedLayoutObject).articles!.push(art as PublishableDocument);
                        }
                }
            }
        }
        if (layouts.some(l => l._type === ItemType_ListPosterRow)) {
            queryClient = new QueryClient();
            const promises: Promise<void>[] = [];
            for (const layout of layouts) {
                if (layout._type === ItemType_ListPosterRow && ((layout as GroupedLayoutObject).articles?.length ?? 0) > 0) {
                    const streamingIds = ((layout as GroupedLayoutObject).articles![0] as IMovieList).streamingIds.slice(0, 6);
                    const prefetchPopMoviesPromise = queryClient.prefetchQuery({
                        queryKey: buildFilminfoQueryKey(movieListPosterQuery, { streamingIds }),
                        queryFn: filminfoRequest
                    });
                    promises.push(prefetchPopMoviesPromise);
                }
            }
            await Promise.all(promises);
        }

        let props: any = {
            page,
            customPageProps,
            layouts
        };

        if( pageId.endsWith("_TEST")) {
            props.robots = "noindex";
        }

        if (queryClient) {
            props.dehydratedState = dehydrate(queryClient);
        }
        return {
            props
        };
    });
};
//#endregion

const AD_PROXIMITY = 0;


//#region [Props]
type FrontpageV2Props = {
    page: IForsidenV2;
    customPageProps?: CustomPageProps,
    layouts: GroupedLayoutObject[];
} & ICommonProps
//#endregion

export default function Forsiden({ page, appData, customPageProps, layouts }: FrontpageV2Props) {
    return (
        <>
            <Head>
                <title>{customPageProps?.pageTitle ?? envConfig.NEXT_PUBLIC_DEFAULT_TITLE}</title>
                {customPageProps?.sgTopLogo ? <>
                    <meta key="ogDescription" property="og:description" content="Se hvor du kan streame filmer og serier. Hele Norges filmportal!" />
                    <meta key="metaDescription" name="description" content="Se hvor du kan streame filmer og serier. Hele Norges filmportal!" />
                </> : <>
                    <meta key="ogDescription" property="og:description" content={envConfig.NEXT_PUBLIC_DEFAULT_DESCRIPTION} />
                    <meta key="metaDescription" name="description" content={envConfig.NEXT_PUBLIC_DEFAULT_DESCRIPTION} />
                </>}

                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {/** TODO: Gjør ferdig denne */}
            </Head>
            <AdWrapper ads={AD_SETUP[(customPageProps?.adSetup ?? "frontpage")]} defaultProximity={AD_PROXIMITY}>
                <BgImagePageWrapper showSponsorBg={customPageProps?.hasCustomBg} adTargetId={customPageProps?.customBgTargetAdId}>
                    <SPageSection>
                        <InfoBox messageBanner={appData.settings} />
                        {customPageProps?.sgTopLogo && <SSGLogo />}
                        {(layouts?.length ?? 0) > 0 && <ArticleBlockV2 items={layouts}
                            commonAppData={appData}
                        >
                            {AD_SETUP[(customPageProps?.adSetup ?? "frontpage")]}
                        </ArticleBlockV2>}
                    </SPageSection>
                </BgImagePageWrapper>
                {(customPageProps?.hasStickyBottomAd ?? false) && <StickyBottomAd isPackshot={false}/>}
            </AdWrapper>
        </>
    )

}

//#region [Styles]
const SPageSection = styled.section`
	--page-padding: 10px;

	min-height: 100vh;
	min-height: 100dvh;
	width: calc(100% - (var(--page-padding) * 2));
	max-width: ${theme.maxContentWidth}px;
	margin: 10px auto 10px auto;
	display: flex;
	flex-direction: column;
	gap: 10px;



	@media ${theme.mq.desktop} {
		gap: 35px;
		margin: 35px auto 35px auto;
	}

	@media screen and (max-width: ${theme.maxContentWidth}px) {
		margin-left: var(--page-padding);
		margin-right: var(--page-padding);
	}
`;

const SSGLogo = styled(FilmwebSGLogoSvg)`
	display: block;
	max-width: min(75%, 800px);
	margin: 30px auto;
	line-height: 1;

	@media ${theme.mq.desktop} {
		margin: 0 auto;
	}
`;
//#endregion

const afterContentFunc = (counter: number) => {
    return counter <= 10;
}

// TODO: Annonser for Filmnytt seksjon

const BODYADS: AdUnitWithPosition[] = [
    { auId: '000000000005377a', auW: '100%', targetId: 'FW_midt1_forside', position: 7, showAfterContentIf: afterContentFunc },
    { auId: '0000000000053791', auW: '100%', targetId: 'FW_midt2_forside', position: 10, showAfterContentIf: afterContentFunc },
    { auId: '00000000000537ae', auW: '100%', targetId: 'FW_bunn_forside', position: 11, showAfterContentIf: afterContentFunc },
];

const ADS: AdUnitWithPosition[] = [
    { auId: '0000000000349907', auW: '300', auH: '250', targetId: 'FW_MPU_forside' },
    { auId: '00000000000537e0', auW: '100%', targetId: 'FW_fast_plass_forside' },
    { auId: '0000000000351c9f', auW: '100%', targetId: 'FW_topp_forside' },

    ...BODYADS,
    { auId: '0000000000248ebf', auW: '1px', auH: '1px', targetId: 'FW_sg_search_box' },
];

const NEWS_ADS: AdUnitWithPosition[] = [
    { auId: '31e2ab', auW: '100%', targetId: 'filmnytt-topp', position: 0 },
    { auId: '32098f', auW: '100%', targetId: 'filmnytt-midt1', position: 2 },
    { auId: '320994', auW: '100%', targetId: 'filmnytt-midt2', position: 4 },
    { auId: '320996', auW: '100%', targetId: 'filmnytt-midt3', position: 6 },
    { auId: '320997', auW: '100%', targetId: 'filmnytt-bunn', position: -1, showAfterContentIf: (counter, isLast) => true },
];

const SG_ADS: AdUnitWithPosition[] = [
    /*{ auId: '00000000000869e3', auW: '100%', auH: '300', targetId: 'SG3_top_front_page', position: 3 },*/
    { auId: '383205', auW: '100%', auH: '300', targetId: 'SG3_top_front_page', position: 3 },
    /*{ auId: '00000000000869e4', auW: '100%', auH: '300', targetId: 'SG3_middle1_front_page', position: 7 },
    { auId: '00000000000869e4', auW: '100%', auH: '300', targetId: 'SG3_middle2_front_page', position: 9 },*/
    { auId: '383206', auW: '100%', auH: '300', targetId: 'SG3_middle1_front_page', position: 7 },
    { auId: '383206', auW: '100%', auH: '300', targetId: 'SG3_middle2_front_page', position: 9 },
    { auId: '00000000000869e5', auW: '100%', auH: '300', targetId: 'SG3_bottom_front_page', position: 10 },
    { auId: '0000000000248ebf', auW: '1px', auH: '1px', targetId: 'FW_sg_search_box' },
    //{ auId: '365245', auW: '1px', auH: '1px', targetId: 'SponsorTrackingPixel' },
    { auId: '3957cd', auW: '50vw', targetId: 'FW_bottomSticky', requestMode: "hasTarget" },
];

const AD_SETUP = {
    "frontpage": ADS,
    "streamingguide": SG_ADS,
    "news": NEWS_ADS
}